import React from "react";

import { theme } from "../constants";

export const ProductPrice = ({ containerStyle, item }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        ...containerStyle,
      }}
    >
      <span
        style={{
          fontSize: item.oldPrice ? 14 : 12,
          ...theme.fonts.Mulish_600SemiBold,
          color: theme.colors.mainColor,
        }}
      >
        ₹{item.item_price}
      </span>
    </div>
  );
};
